import React, {Component} from 'react';
import PropTypes from "prop-types";
import { Border } from '../EventDetails/styles';
import { ActivityBar, ActivityBarFill, TopCardContainer, ScoreCard, ScoreCardHeader,IconContainer,PercentageCard,BottomText } from './styles';
import { HeartIcon, InformationIcon, BiometricIcon, TotalHealthCostIcon } from '../../utils/icons';
import { fetchApi } from '../../utils/methods';
import { GetCompanyBiometricDetails } from '../../redux/constants/apiConstants';
import { OverlayTrigger, Popover } from "react-bootstrap";


export default class TopHealthCards extends Component{
  constructor(){
    super();
    this.state = {
      biomatric_taken_users_count: 0,
      biomatric_taken_users_percentage: 0,
      company_users: 0,
      one_year_health_cost: 0,
      three_year_health_cost: 0
    }
    this.headerTitleRef = React.createRef();
  }

  async getCompanyBiometricDetails(){
    const result = await fetchApi(`${GetCompanyBiometricDetails}/${this.props?.companyInfo?.id}/${this.props.selectedYearInfo}`, "GET");

    this.setState({
      biomatric_taken_users_count: result?.data?.biomatric_taken_users_count,
      biomatric_taken_users_percentage: result?.data?.biomatric_taken_users_percentage,
      company_users: result?.data?.company_users,
      one_year_health_cost: result?.data?.one_year_health_cost,
      three_year_health_cost: result?.data?.three_year_health_cost
    })
    // window.console.log("line 19 result :- ", result);
  }

  componentDidMount(){
    this.getCompanyBiometricDetails();
  }

  render(){
    const { biomatric_taken_users_count, biomatric_taken_users_percentage, company_users, one_year_health_cost, three_year_health_cost } = this.state;
    const { totalAffectedUsers, averageBiometricPayload}= this.props;

    // window.console.log("averageBiometricPayload",averageBiometricPayload)
    const TotalHealthCards = [
      {
        id:1,
        title: averageBiometricPayload?.[0]?.title,
        background_color: 'rgba(133, 192, 234, 0.2)',
        color:'#85C0EA',
        percentage: averageBiometricPayload?.[0]?.value,
        subtitle: 'Company Health',
        icon: HeartIcon(),
      },
      {
        id:2,
        title: 'Total Biometrics',
        background_color: 'rgba(220, 148, 181,0.2)',
        color:'#DC94B5',
        percentage: biomatric_taken_users_count,
        subtitle: 'Assessment Taken',
        icon: BiometricIcon(),
      },
     
    ]

    const totalCostCards = [
      {
        id:3,
        title: 'Potential Costs Year 1',
        background_color: 'rgba(118, 171, 120, 0.2)',
        color:'#76AB78',
        // total_cost: '$135,912',
        subtitle: 'Health Cost',
        icon: TotalHealthCostIcon() ,
        text: "Total 1 year costs, coming from top health risks and top health concerns"
        // affected_user: 451,
      },
      {
        id:4,
        title: 'Potential Costs Year 3 ',
        background_color: 'rgba(175, 135, 193, 0.2)',
        color:'#AF87C1',
        // total_cost: '$407,736',
        subtitle: 'Health Cost',
        icon: TotalHealthCostIcon('#AF87C1'),
        text: "Total 3 year costs, coming from top health risks and top health concerns"
        // affected_user: 451,
      },
     
    ]
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "10px 0px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
          marginTop:"7px"
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );

    return(
      <TopCardContainer>    
        {/* first 2 card */}
        <TopCardContainer>
          { TotalHealthCards && TotalHealthCards.map((item ,index) =>
            <ScoreCard key={index}>
              <ScoreCardHeader>
                <IconContainer isBackground = {item.background_color}>
                  {item.icon}
                </IconContainer>
                <div className="scoreTitile">
                  {item.title}
                </div>
              </ScoreCardHeader>
              <PercentageCard>
                <span style={{color: item.color}}>{ item.id === 1 ? `${item.percentage}%` : item.percentage }</span> {item.subtitle}
              </PercentageCard>
              <Border style={{background:'rgba(0, 92, 135, 0.1)',margin:'0px',width:"100%",marginTop:"20px", marginBottom: "20px"}}/>
              { item.id === 1 ?
                (<ActivityBar style={{ display: 'flex', alignItems: 'center' }} barHeight="20px" isRadius="20px" fillColor="rgba(33, 153, 193, 1)" backgroundFill={'rgba(0, 92, 135, 0.05)'}>
                  <ActivityBarFill  isRadius="20px" width={`${item.percentage}%`} fillColor= '#85C0EA'>
                  </ActivityBarFill>
                </ActivityBar>) :
                ( <BottomText>
                  <span style={{color: item.color}}>{biomatric_taken_users_percentage}% Users</span>completed out of {company_users}
                </BottomText>)
              }
            </ScoreCard>
          )}
        </TopCardContainer>

         
        {/* last 2 card */}
        <TopCardContainer>
          { totalCostCards && totalCostCards.map((item ,index) =>
            <ScoreCard key={index}>
              <ScoreCardHeader>
                <IconContainer  isBackground = {item.background_color}>
                  {item.icon}
                </IconContainer>
                <div>
                  <p style={{width:'150px',color:"#005c87",fontFamily:'rubik-medium',fontSize:'18px',marginBottom:0}}>{item.title}</p>
                </div>
                <div style={{ width:'24px', height:'24px',alignSelf:'flex-start', cursor:"pointer"}}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={popover(item.text)}
                  >
                    {InformationIcon()}
                  </OverlayTrigger>
                </div>
              </ScoreCardHeader>
              <PercentageCard>
                <span style={{color: item.color}}>{ item.id === 3 ? one_year_health_cost : three_year_health_cost }</span>{item.subtitle}
              </PercentageCard>
              <Border style={{background:'rgba(0, 92, 135, 0.1)',margin:'0px',width:"100%",marginTop:"20px", marginBottom: "20px"}}/>
              <BottomText>
                <span style={{color: item.color}}>{totalAffectedUsers} Users</span> affected by these risks
              </BottomText>
            </ScoreCard>
          )}
        </TopCardContainer>
      </TopCardContainer>  
   
    )
  }
}

TopHealthCards.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  childNumber: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
  companyInfo: PropTypes.object,
  selectedYearInfo: PropTypes.string,
  totalAffectedUsers: PropTypes.number,
  averageBiometricPayload: PropTypes.object
};